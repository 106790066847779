import { render, staticRenderFns } from "./AvppccAcuidadeVisualParaPertoComCorrecaoForm.vue?vue&type=template&id=3d43d786&scoped=true"
import script from "./AvppccAcuidadeVisualParaPertoComCorrecaoForm.vue?vue&type=script&lang=js"
export * from "./AvppccAcuidadeVisualParaPertoComCorrecaoForm.vue?vue&type=script&lang=js"
import style0 from "./AvppccAcuidadeVisualParaPertoComCorrecaoForm.vue?vue&type=style&index=0&id=3d43d786&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d43d786",
  null
  
)

export default component.exports